<template>
    <div class="field-container">
        
        <div v-if="field.showName != false" class="label">{{field.title}}</div>

        <div v-if="field.layout == 'color'" class="colors">
            <div
                v-for="option in field.options"
                :key="`option${option._id}`"
                @click="handleChange(option._id)"
                class="color-container"
                :class="{ selected: value == option._id }">
                <div class="color" :style="`background-color:${option.extra && option.extra.color}`"></div>
            </div>
        </div>

        <div v-else-if="field.layout == 'image'" class="thumbnails">
            <div
                v-for="option in field.options"
                :key="`option${option._id}`"
                @click="handleChange(option._id)"
                class="thumbnail"
                :class="{ selected: value == option._id }"
                :style="`width:calc(100% / ${field.columnCount || 4});`">
                <div class="thumbnail-image" :style="`background-image:url(${option.thumbnail.url})`">
                    <div class="thumbnail-border"></div>
                </div>
                <div v-if="field.showOptionNames != false" class="thumbnail-label">{{option.name}}</div>
            </div>
        </div>

        <div v-else>
            <select v-on:change="handleChange($event.target.value)" :value="value">
                <option
                    v-for="option in field.options"
                    :key="`option${option._id}`"
                    :value="option._id">
                    {{option.name}}
                </option>
            </select>
        </div>

        <div v-if="translate(field.description).length" class="description">
            {{translate(field.description)}}
        </div>

    </div>
</template>

<script>

import language from '../../../mixins/language'

export default {
    props: [ 'field' ],
    mixins: [ language ],
    computed: {
        config() {
            return this.$store.state.configs[this.$store.state.activeConfig]
        },
        activeGroup() {
            return this.$store.state.activeGroup
        }
    },
    mounted() {
        const configField = this.config.find(field => field._id === this.field._id)
        if (configField) this.value = configField.optionId
        this.updateThumbnailHeight()
        window.addEventListener('resize', () => {
            this.updateThumbnailHeight()
        })
    },
    watch: {
        activeGroup() {
            this.updateThumbnailHeight()
        }
    },
    data() {
        return {
            value: null
        }
    },
    methods: {
        handleChange(optionId) {
            this.value = optionId
            this.$emit('change', optionId)
        },
        updateThumbnailHeight() {
            const thumbnails = document.querySelectorAll('.thumbnail-image')
            thumbnails.forEach(thumbnail => {
                thumbnail.style.height = thumbnail.offsetWidth+'px'
            })
        }
    }
}

</script>

<style src="./Select.scss" lang="scss" scoped></style>