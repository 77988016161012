import Vue from 'vue'

export default Vue.extend({
    computed: {
        language() {
            return this.$store.state.language
        }
    },
    methods: {
        translate(o: any) {
            const x = o.find((t: any) => t.lang === this.language) || { value: '' }
            if (x) return x.value
        }
    }
})